import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './IconLink.module.css';
import classnames from 'classnames';

const IconLink = ({ icon, size, to, color, className }) => (
  <a href={to}>
    <FontAwesomeIcon icon={icon} size={size} color={color} className={classnames(styles.icon, className)} />
  </a>
);

export default IconLink;
