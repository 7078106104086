import React, { Fragment } from "react"
import IconLink from "../../components/icon-link/IconLink"
import { library } from "@fortawesome/fontawesome-svg-core"
import { faLinkedin, faGithub, faCodepen } from "@fortawesome/free-brands-svg-icons";
import colors from '../gatsby-plugin-theme-ui/colors';
import styles from './bio-content.module.css';

library.add([
  faLinkedin,
  faGithub,
]);

export default () => (
  <Fragment>
    Front End Developer, fan of creative coding, open source libraries, cats, coffee and marzipan.
    Philologist by trade.
    <br />
    <IconLink to="https://www.linkedin.com/in/jakubantolak/" size="lg" icon={faLinkedin} color={colors.background} className={styles.firstIcon} />
    <IconLink to="https://www.github.com/afternoon2" size="lg" icon={faGithub} color={colors.background} />
    <IconLink to="https://codepen.io/jakub_antolak" size="lg" icon={faCodepen} color={colors.background} />
  </Fragment>
)
